import React from "react";

import MainNav from "@/components/MainNav";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

type PageTitleHeroProps = {
  title?: string;
  backgroundUrl?: string;
};

const PageTitleHero: React.FC<PageTitleHeroProps> = ({
  title,
  backgroundUrl
}) => {
  const { spacing } = useTheme();
  return (
    <Box
      sx={{
        backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : undefined,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: spacing(70),
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <MainNav />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1
        }}
      >
        <Paper sx={{ mb: 10, p: 5, opacity: 0.95 }} elevation={15}>
          <Typography variant="h1">{title}</Typography>
        </Paper>
      </Box>
    </Box>
  );
};

PageTitleHero.displayName = "PageTitleHero";

export default PageTitleHero;
