import { styled } from "@ui/theme";

const ContentRenderer = styled("div")(({ theme }) => ({
  color: "inherit",
  overflowWrap: "break-word",
  a: {
    color: theme.palette.primary.main
  }
}));

ContentRenderer.displayName = "ContentRenderer";

export default ContentRenderer;
