// https://www.sitepoint.com/gatsby-mdx-blog/
// https://talohana.com/blog/gatsby-graphql-code-generator
import React from "react";
import { graphql, PageProps } from "gatsby";

import { Helmet } from "react-helmet";

import { MDXRenderer } from "gatsby-plugin-mdx";

import PageTitleHero from "@/sections/PageTitleHero";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import ContentRenderer from "@ui/core/ContentRenderer";

interface MDXPageProps extends PageProps {
  data: {
    mdx?: {
      slug?: string;
      body?: string;
      frontmatter?: {
        date?: string;
        title?: string;
        background_url?: string;
      };
    };
  };
}

const query = graphql`
  query POST_BY_SLUG($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      frontmatter {
        date
        title
        background_url
      }
    }
  }
`;

const MDXPage: React.FC<MDXPageProps> = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>
          {data.mdx?.frontmatter?.title ?? "Welcome"} | Adam Cooper
        </title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>

      <PageTitleHero
        title={data.mdx?.frontmatter?.title}
        backgroundUrl={data.mdx?.frontmatter?.background_url}
      />

      <Container maxWidth="lg">
        <Paper elevation={10} sx={{ p: 4, mb: { xs: 5, md: 15 }, mt: -5 }}>
          <ContentRenderer>
            <MDXRenderer>{data?.mdx?.body ?? ""}</MDXRenderer>
          </ContentRenderer>
        </Paper>
      </Container>
    </>
  );
};

MDXPage.displayName = "MDXPage";

export { query };
export default MDXPage;
